import PaperViewer from '../components/PaperViewer'

function switchColor (darkMode = false) {
  if (darkMode) {
    document.body.classList.add('darkmode')
  } else {
    document.body.classList.remove('darkmode')
  }
}

class PaperNavigation {
  static bootstrap () {
    const subNav = document.getElementsByClassName('c-subnav')[0]
    const fontSizer = document.getElementsByClassName('c-fontsizer')[0]
    subNav.addEventListener('click', (event) => {
      const action = event.target.getAttribute('data-action')

      switch (action) {
        case 'close':
          PaperViewer.deactivate()
          break
        case 'fontsize':
          fontSizer.classList.toggle('close')
          break
        case 'dark':
          switchColor(true)
          break
        case 'normal':
          switchColor()
          break
        case 'overview':
          document.getElementsByClassName('c-contents')[0].classList.toggle('close')
          break
        default:
          break
      }
    })
    subNav.addEventListener('touchmove', (event) => {
      if (event.target.id !== 'fontsize') {
        event.preventDefault()
      }
    }, false)

    const fontSizeRange = document.getElementsByClassName('js-font-range')[0]
    fontSizeRange.addEventListener('input', (event) => {
      const value = event.currentTarget.value
      const pages = document.getElementsByClassName('c-paper__page')
      for (let page of pages) {
        page.style.fontSize = `${value}px`
      }
    })
  }

  static close () {
    document.getElementsByClassName('c-contents')[0].classList.add('close')
    document.getElementsByClassName('c-fontsizer')[0].classList.add('close')
  }
}

export default PaperNavigation
