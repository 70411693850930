import Form from '../lib/xhtml-form.min';
import AuthService from '../module/AuthService';

function determineRedirectUrl() {
    if (window.location.hash) {
        const encodedRedirectUrl = window.location.hash.replace('#', '');
        return decodeURIComponent(encodedRedirectUrl);
    }
    return 'index.html';
}

class Index {
    static bootstrap() {
        AuthService.getService().logout();
        const xhtmlInstantForm = Form.create(document.querySelector('#login-form'));
        xhtmlInstantForm.apply({
            success(xhtmlForm) {
                const entry = xhtmlForm.entries.data[0];
                AuthService.getService().storeCredentials(entry.data.userId, entry.data.token, entry.data.password, entry.data.algorithm);
                //window.location.replace(determineRedirectUrl());
                window.location = 'index.html';
            },
            error: function (jqXHR) {
                var xhtmlForm = jqXHR.xhtmlForm;
                if (xhtmlForm && xhtmlForm.form && xhtmlForm.form.error) {
                    var generalError = xhtmlForm.form.error;
                    switch (generalError.type) {
                        case "http://spec.micro-services.com/probs/not-activated":
                            var message = "Du musst deine Email Adresse bestätigen, bevor du dich einloggen kannst.";
                            if (generalError.data && generalError.data.uri) {
                                message += "<br>Um die Bestätigungs-Email erneut zu versenden <a href=\"" + generalError.data.uri + "\" target=\"_blank\">klicke hier</a>.";
                            }
                            document.getElementsByClassName("general-error-message")[0].innerHTML = message;
                            break;
                    }
                }
            }
        });
    }

    static logout() {
        AuthService.getService().logout();
    }
}

export {Index as default};
