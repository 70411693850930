import Fastclick from './lib/fastclick'
import Navigation from './module/Navigation'
import IndexPage from './page/index'
import LoginPage from './page/login'
import SignupPage from './page/signup'
import Password from './page/password'
import PaperNavigation from './components/PaperNavigation'
import Contents from './components/Contents'

function init () {
  Fastclick.attach(document.body)

  if (document.getElementById('index-page')) {
    Navigation.init()
    IndexPage.bootstrap()
    PaperNavigation.bootstrap()
    Contents.bootstrap()
  }

  if (document.getElementById('login-page')) {
    LoginPage.bootstrap()
  }

  if (document.getElementById('signup-page')) {
    SignupPage.bootstrap()
  }

  if (document.getElementById('password-page')) {
    Password.bootstrap('#password-form')
  }

  if (document.getElementById('logout-page')) {
    LoginPage.logout()
  }

  if (document.getElementById('pdf-viewer-page')) {
    //
  }
}

document.addEventListener('DOMContentLoaded', () => {
  init()
})
