import PageCompass from './PageCompass'

function scrollToActiveElement () {
  const paper = document.getElementsByClassName('c-paper')[0]
  const activePageNode = document.querySelectorAll('.c-paper__page.active')[0]
  if (window.innerWidth <= 720) {
    paper.scroll({
      top: 0,
      left: activePageNode.offsetLeft,
      behavior: 'smooth'
    })
  } else {
    document.documentElement.scroll({
      top: activePageNode.offsetTop,
      left: 0,
      behavior: 'smooth'
    })
  }
  const id = parseInt(activePageNode.getAttribute('data-page-id'))
  Contents.switchActiveItemById(id)
  PageCompass.setToActivePageNumberAndPosition()
}

class Contents {
  static bootstrap () {
    const contents = document.getElementsByClassName('js-contents-list')[0]

    contents.addEventListener('click', (event) => {
      const clickedNode = event.target
      const id = parseInt(clickedNode.getAttribute('data-id'))
      const activePageNode = document.querySelectorAll('.c-paper__page.active')[0]
      if (activePageNode) {
        activePageNode.classList.remove('active')
      }
      const headlines = document.getElementsByClassName('c-paper')[0].getElementsByTagName('h3')
      headlines[id].parentNode.classList.add('active')
      document.getElementsByClassName('c-contents')[0].classList.add('close')
      setTimeout(scrollToActiveElement, 500)
    })

    document.getElementsByClassName('js-contents__header')[0].addEventListener('click', () => {
      document.getElementsByClassName('c-contents')[0].classList.add('close')
    })
  }

  static fill () {
    const headlines = document.getElementsByClassName('c-paper')[0].getElementsByTagName('h3')
    const contents = document.getElementsByClassName('js-contents-list')[0]
    contents.innerHTML = ''
    for (let index = 0; index < headlines.length; index++) {
      const listNode = document.createElement('li')
      listNode.setAttribute('data-id', index)
      listNode.id = `listItem-${index}`
      listNode.innerText = headlines[index].textContent
      contents.appendChild(listNode)
    }
    document.querySelector('.js-contents-list li').classList.add('active')
  }

  static switchActiveItemById (activePageId) {
    const activeListItem = document.getElementsByClassName('c-contents')[0].getElementsByClassName('active')[0]
    if (activeListItem) {
      activeListItem.classList.remove('active')
    }
    document.getElementById(`listItem-${activePageId}`).classList.add('active')
  }
}

export default Contents
