import PaperNavigation from './PaperNavigation'
import PageCompass from './PageCompass'
import Contents from './Contents'

require('smoothscroll-polyfill').polyfill()
require('intersection-observer')

let observerConfig = {
  root: null,
  rootMargin: '0px',
  threshold: [0.15]
}

const fluidPaperViewClass = 'fluid-view'
let switchPaper = false
let newActivePaper

function onChange (changes, observer) {
  changes.forEach(change => {
    if (change.intersectionRatio > 0.15) {
      switchPaper = true
      newActivePaper = change.target
    } else if (change.intersectionRatio < 0.15) {
      switchPaper = false
    }
  })
}

function scrollToActivePage (paper) {
  let activePageNode = document.querySelectorAll('.c-paper__page.active')[0]
  if (activePageNode == null) {
    activePageNode = document.querySelector('.c-paper__page')
  }
  document.getElementsByClassName('c-paper')[0].scroll({
    top: 0,
    left: activePageNode.offsetLeft,
    behavior: 'smooth'
  })
  setTimeout(() => {
    paper.classList.remove('lock')
    PageCompass.setToActivePageNumberAndPosition()
  }, 5)
}

function handleTouchEnd (event) {
  const paper = event.currentTarget
  paper.classList.add('lock')
  paper.removeEventListener('touchend', handleTouchEnd)

  if (switchPaper) {
    const activePage = document.querySelectorAll('.c-paper__page.active')[0]
    if (activePage) {
      activePage.classList.remove('active')
    }
    newActivePaper.classList.add('active')
    Contents.switchActiveItemById(parseInt(newActivePaper.getAttribute('data-page-id')))
  }
  scrollToActivePage(paper)
}

function handleHorizontalScroll (event) {
  const paper = event.currentTarget
  paper.removeEventListener('scroll', handleHorizontalScroll)
  paper.addEventListener('touchend', handleTouchEnd)
}

function handleTouchStart (event) {
  const paper = event.currentTarget
  paper.addEventListener('scroll', handleHorizontalScroll)
}

class PaperViewer {
  static bootstrap () {
    const paper = document.getElementsByClassName('c-paper')[0]
    paper.addEventListener('touchstart', handleTouchStart)

    let observer = new IntersectionObserver(onChange, observerConfig)
    const pages = document.querySelectorAll('.c-paper__page')
    for (let index = 0; index < pages.length; index++) {
      pages[index].setAttribute('data-page-id', index)
      observer.observe(pages[index])
    }
  }

  static activate () {
    document.body.classList.add(fluidPaperViewClass)
  }

  static deactivate () {
    document.body.classList.remove(fluidPaperViewClass)
    document.body.classList.remove('darkmode')
    PaperNavigation.close()
  }
}

export {
  PaperViewer as default
}
