class Utils {

    static createElement(html) {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(html, "text/html");
        return (document.body && document.body.firstChild) ? document.body.firstChild : null;
    }

}

export {Utils as default};
