import authorizationService from '../lib/auth.min';

class AuthService {

    static getService() {
        if (!this.authorizationService) {
            this.authorizationService = authorizationService('https://service-auth.micromerce.com/applications/9dcce281-0961-494f-bca0-7215008fa76b');
        }
        return this.authorizationService;
    }

}

export {AuthService as default};
