import Form from '../lib/xhtml-form.min';

class Index {

    static bootstrap() {
        const xhtmlInstantForm = Form.create(document.querySelector('#signup-form'));
        xhtmlInstantForm.apply({
            success(xhtmlForm) {

            }
        });
    }

}

export {Index as default};
