import AuthService from '../../module/AuthService'
import PdfView from './PdfView'
import PaperViewer from '../../components/PaperViewer'
import Contents from '../../components/Contents'
import Utils from '../../module/Utils'
import snippets from './snippets'

const activePdfClass = 'active-pdf'
const pdfLinkElementClass = 'js_pdf-link'
const fluidHtmlElementClass = 'js_fluid-html'

function linkHandoutAnchorsToHandoutView (snippet) {
  linkPdfView(snippet)
  linkFluidHtmlView(snippet)
}

function linkFluidHtmlView (snippet) {
  const links = snippet.getElementsByClassName(fluidHtmlElementClass)
  const fluidPaperNode = document.getElementsByClassName('js-fluid-paper')[0]

  const spinner = document.createElement('i')
  spinner.classList.add('icon-spin4', 'animate-spin')

  for (let link of links) {
    link.addEventListener('click', (event) => {
      fluidPaperNode.innerHTML = ''
      fluidPaperNode.appendChild(spinner)
      PaperViewer.activate()
      event.preventDefault()
      fetch(event.currentTarget.href + '/alternatives/fullcontent')
        .then((res) => {
          return res.text()
        })
        .then((data) => {
          fluidPaperNode.innerHTML = data
          PaperViewer.bootstrap()
          Contents.fill()
        })
    })
  }
}

function linkPdfView (snippet) {
  const links = snippet.getElementsByClassName(pdfLinkElementClass);
  [].forEach.call(links, (link) => {
    if (link && link.href.indexOf('.pdf') !== -1) {
      link.addEventListener('click', (event) => {
        event.preventDefault()
        const activePdf = event.currentTarget
        activePdf.classList.add(activePdfClass)
        const activePdfUrl = event.currentTarget.href
        PdfView.setActivePdfUrl(activePdfUrl)
        PdfView.activate()
      })
    }
  })
}

function replaceUnobtrusiveAnchors (container) {
  const loadSnippetPromises = []
  const anchorElements = container.querySelectorAll('a.unobtrusive')
  for (let i = 0; i < anchorElements.length; i++) {
    const anchorElement = anchorElements[i]
    const url = anchorElement.getAttribute('href')
      .replace('snippet.micromerce.com/', 'service-snippet.micromerce.com/snippets/')
    const loadSnippetPromise = snippets.loadSnippet(url)
      .then((content) => {
        anchorElement.parentNode.replaceChild(Utils.createElement(content), anchorElement)
      })
    loadSnippetPromises.push(loadSnippetPromise)
  }
  return Promise.all(loadSnippetPromises)
}

function releaseTimedItems (snippet) {
  const currentDate = new Date()
  const timedItems = snippet.getElementsByClassName('timed-item')
  for (let i = 0; i < timedItems.length; i++) {
    const timedItem = timedItems[i]
    const publishDate = new Date(timedItem.dataset.date + 'T' + timedItem.dataset.time)
    if (publishDate < currentDate) {
      timedItem.classList.remove('unreleased')
    }
  }
}

function showCategoryButtons (trainingDiv) {
  const categoryButtons = document.getElementsByClassName('js_category-button');
  [].forEach.call(categoryButtons, (button, index) => {
    const categoryName = button.getAttribute('data-target')
    if (index === 0) {
      button.classList.add('highlight')
    } else {
      button.classList.remove('highlight')
    }
    if (trainingDiv.getElementsByClassName(categoryName).length === 0) {
      button.style.display = 'none'
    } else {
      button.style.display = 'flex'
    }
  })
}

function hideSpinner () {
  document.getElementsByClassName('js_spinner')[0].classList.add('hidden')
}

function fillSnippet (html, activate = false) {
  const trainingDiv = document.createElement('div')
  trainingDiv.classList.add('training')
  trainingDiv.innerHTML = html
  const head = trainingDiv.getElementsByClassName('head')[0]
  head.addEventListener('click', (event) => {
    const clicked = event.currentTarget
    const trainingDiv = clicked.parentNode
    trainingDiv.classList.add('active')
    trainingDiv.getElementsByClassName('handouts')[0].classList.add('active')
    showCategoryButtons(trainingDiv)
    document.body.classList.add('training-view')
    document.getElementsByClassName('js_training-info')[0].innerHTML = clicked.getElementsByClassName(
      'js_training-info')[0].innerHTML + '<i class="icon-left-open-big"></i>'
  })
  document.querySelector('.micromerce-snippet').appendChild(trainingDiv)
  replaceUnobtrusiveAnchors(trainingDiv)
    .then(() => linkHandoutAnchorsToHandoutView(trainingDiv))
    .catch(() => linkHandoutAnchorsToHandoutView(trainingDiv))
  releaseTimedItems(trainingDiv)
}

function logout () {
  window.location = 'login.html'
}

class Index {
  static bootstrap () {
    if (!AuthService.getService().isConnected()) {
      return logout()
    }
    snippets.listSnippetUrls()
      .then((snippetUrls) => {
        Promise
          .all(snippetUrls
            .map((snippetUrl) => {
              return snippets.loadSnippet(snippetUrl)
                .then((html) => html)
                .catch(() => 'ua')
            }))
          .then((snippets) => {
            const authorizedSnippets = snippets.filter(function (elem) {
              return elem !== 'ua'
            })
            hideSpinner()
            if (authorizedSnippets.length === 0) {
              document.querySelector('.nocourses').classList.remove('hidden')
            } else {
              authorizedSnippets.forEach((snippet) => fillSnippet(snippet, authorizedSnippets.length === 1))
            }
          })
      })
      .catch(logout)
    document.getElementsByClassName('js-head')[0].addEventListener('click', () => {
      document.body.classList.remove('training-view')
      document.body.classList.remove('pdf-view')
      const elementsToInactivate = document.getElementsByClassName('active')
      while (elementsToInactivate.length > 0) {
        elementsToInactivate[0].classList.remove('active')
      }
    })
  }
}

export {
  Index as default
}
