const pdfViewClass = 'pdf-view'

class PdfView {
  static setActivePdfUrl (pdfUrl) {
    const container = document.getElementById('paper')
    const iframe = document.createElement('iframe')
    iframe.id = 'pdf-viewer'
    iframe.src = `assets/js/ext-lib/web/pinch-viewer-v2.2.html?file=${pdfUrl}`
    container.innerHTML = ''
    window.location.hash = pdfUrl
    container.appendChild(iframe)
  }

  static activate () {
    document.body.classList.add(pdfViewClass)
  }

  static deactivate () {
    const iframe = document.getElementById('pdf-viewer')
    if (iframe) {
      iframe.parentNode.removeChild(iframe)
    }
    document.body.classList.remove(pdfViewClass)
  }
}

export {
  PdfView as default
}
