import AuthService from '../../../module/AuthService'

export default (() => {
  const SNIPPET_URL_MASTER = 'https://service-snippet.micromerce.com/snippets/1c5c9fa0-1379-4199-837a-761a938247c0'

  const SNIPPET_URI_TEMPLATE = 'https://service-snippet.micromerce.com/snippets/{snippetId}/alternatives/web'

  const listSnippetUrls = function () {
    const snippetUriTemplate = localStorage.getItem('snippet-uri-template') || SNIPPET_URI_TEMPLATE
    return new Promise(function (resolve, reject) {
      listSnippetIds()
        .then((snippetIds) => resolve(snippetIds.map((id) => { return snippetUriTemplate.replace('{snippetId}', id) })))
        .catch(reject)
    })
  }

  const listSnippetIds = function () {
    return new Promise(function (resolve, reject) {
      AuthService.getService().ajax({
        url: SNIPPET_URL_MASTER,
        success: (html) => resolve(extractSnippetIdsFromHtml(html)),
        error: reject
      })
    })
  }

  const loadSnippet = function (url) {
    return new Promise(function (resolve, reject) {
      AuthService.getService().ajax({
        url: url,
        success: resolve,
        error: reject
      })
    })
  }

  const extractSnippetIdsFromHtml = function (html) {
    const regex = /href=".+?\/([^\/]+)"/gi
    const result = []
    let match = regex.exec(html)
    while (match !== null) {
      result.push(match[1])
      match = regex.exec(html)
    }
    return result
  }

  return {
    listSnippetUrls,
    loadSnippet
  }
})()
