class PageCompass {
  static setToActivePageNumberAndPosition () {
    const activePageNode = document.querySelectorAll('.c-paper__page.active')[0]
    const activePageId = parseInt(activePageNode.getAttribute('data-page-id'))
    const pages = document.getElementsByClassName('c-paper__page')
    document.getElementsByClassName('js-pagecompass__page-number')[0].innerHTML = activePageId + 1
    let settledInPercent = `${activePageId / (pages.length - 1) * 100}%`
    /* if (settledInPercent === '100%') {
      settledInPercent = 'calc(100% + 15px)'
    } */
    document.getElementsByClassName('js-pagecompass__settled')[0].style.width = settledInPercent
  }
}

export default PageCompass
