import Form from '../lib/xhtml-form.min'

class Password {
  static bootstrap (formID) {
    const xhtmlInstantForm = Form.create(document.querySelector(formID))
    xhtmlInstantForm.apply({
      success (xhtmlForm) { // eslint-disable-line
        const entry = xhtmlForm.entries.data[0]
        document.getElementById('sent-email').innerText = entry.data.email
      }
    })
  }
}

export { Password as default }
