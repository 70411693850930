import PdfView from '../page/index/PdfView';

const highlightClass = 'highlight';

function highlightButton(button) {
    const buttonToLowlight = document.getElementsByClassName(highlightClass)[0];

    if (buttonToLowlight) {
        buttonToLowlight.classList.remove(highlightClass);
    }
    button.classList.add(highlightClass);
}

function showCategory(button) {
    const activeCategoryName = button.getAttribute('data-target');
    const activeSnippet = document.getElementsByClassName('training active')[0];
    const categoryContainerToShow = activeSnippet.getElementsByClassName(activeCategoryName)[0];
    const categoryContainerToHide = activeSnippet.getElementsByClassName('active')[0];
    if (categoryContainerToHide) {
        categoryContainerToHide.classList.remove('active');
    }
    if (categoryContainerToShow) {
        categoryContainerToShow.classList.add('active');
        window.location.hash = activeCategoryName;
    }
}

function initPaperNavigation() {
    const categoryButtons = document.getElementsByClassName('js_category-button');
    [].forEach.call(categoryButtons, (button) => {
        button.addEventListener('click', (event) => {
            const clickedButton = event.currentTarget;
            if (!clickedButton.classList.contains(highlightClass)) {
                highlightButton(clickedButton);
                showCategory(clickedButton);
                const headerHeight = document.getElementsByClassName('head')[0].offsetHeight;
                if (document.body.scrollTop > headerHeight) {
                    document.body.scrollTop = headerHeight;
                }
            }
            PdfView.deactivate();
        });
    });
}

class Navigation {
    static init() {
        initPaperNavigation();
    }
}

export {Navigation as default};
